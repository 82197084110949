body{
    height: 100vh;
    
}

#root {
    height: 100%;
}
.bg-gradient-primary{
    height: 100%;
}
.container{
    height: 100%;
}
.row {
    height: 100%    ;
}

.card.o-hidden.border-0.shadow-lg.my-5 {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin:unset !important;
}

.bg-gradient-primary {
    background-color: #5BC3C4;
    background-image: unset;
    background-size: cover;
}
.btn-primary {
    color: #fff;
    background-color: #262F40;
    border-color: #262F40;
}

.btn-primary:hover {
    color: #fff;
    background-color: #5BC3C4;
    border-color:  #5BC3C4;
}
.btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #5BC3C4;
    border-color: #5BC3C4;
    box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
}
.alert.alert-danger.alert-dismissible.fade.show {
    margin-top: 20px;
    border-radius: 40px;
}
.bg-login-image {
 
     
    
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #262F40;
    
}